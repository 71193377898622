import React, {useRef} from 'react'
import { observer } from "mobx-react-lite"
import styles from './Layout.module.scss'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import NavLink from '../../components/NavLink';
import Volume from '../common/Volume'

import UserAvatar from './UserAvatar'
import {useNavigate, useLocation} from '@reach/router'
import DrawerNav from './DrawerNav'
import userStore from '../common/userStore';
import subscribeStore from '../account/subscribeStore';
import clsx from 'clsx';
import Video, {useVideo} from '../help/video';


const CreditView = observer(() => {
  const { credit } = userStore;
  const { showSubPlan, subStatusCheck } = subscribeStore;

  // console.log(JSON.stringify(user))

  if (subStatusCheck.isNew && credit <= 0) {
    return <Button variant="contained" disableElevation className={styles.trial} onClick={showSubPlan}>
      Start free trial
    </Button>
  }
  return <Tooltip title={`${credit || 0} credits available`} arrow>
    <div className={styles.credit}>
      <div className={styles.creditIcon}><GraphicEqIcon /></div>
      <div className={styles.creditValue}>{credit || 0}</div>
    </div>
  </Tooltip>
});

const HelpMenu = observer(() => {
  const timeoutRef = useRef(0)
  const navigate = useNavigate()
  const location = useLocation()
  const videoModel = useVideo();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const active = location.pathname.startsWith('/help')
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const showVideo = () => {
    videoModel.show()
    setAnchorEl(null);
  }
  const handle = (e) => {
    if (!e.target || !e.target.className || !e.target.className.indexOf) {
      return;
    }
    const className = e.target.className
    if (className.indexOf('MuiBackdrop-root') == -1) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = 0
      return;
    }
    if (!anchorEl) {
      return;
    }
    const rect = anchorEl.getBoundingClientRect()
    if (e.clientX >= rect.x && e.clientX <= rect.x + rect.width && e.clientY >= rect.y && e.clientY <= rect.y + rect.height) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = 0
      return;
    }
    timeoutRef.current = setTimeout(handleClose, 300);
  }
  return <>
    <Link underline="none" className={clsx(styles.link, styles.help, (open || active) && styles.active)}
      onMouseEnter={handleClick} onClick={handleClick}>Help</Link>
    <Menu
        anchorEl={anchorEl}
        open={open}
        autoFocus={false}
        onClose={handleClose}
        onMouseMove={handle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{root: styles.helpRoot, paper: styles.helpPaper}}
      >
        <MenuItem onClick={() => navigate('/help/faq')}><QuestionAnswerOutlinedIcon />FAQ</MenuItem>
        <MenuItem onClick={showVideo}><VideoLibraryOutlinedIcon />Video Tutorial</MenuItem>
      </Menu>
    <Video open={videoModel.open} onClose={videoModel.hide} />
  </>
})

const AuthHeader = observer(() => {
  const navigate = useNavigate()

  return <AppBar position="sticky" className={styles.header}>
    <Toolbar className={styles.toolbar}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.logo} onClick={() => navigate('/')}></div>
          <NavLink className={styles.link} activeClass={styles.active} to="/" as={['/pack/:id']}>Browse</NavLink>
          <NavLink className={styles.link} activeClass={styles.active} to="/my-library">My Library</NavLink>
          <NavLink className={styles.link} activeClass={styles.active} to="/vocalnet">VocalNet</NavLink>
          <NavLink className={styles.link} activeClass={styles.active} to="/drumnet">DrumNet</NavLink>
        </div>
        <div className={styles.right}>
          <HelpMenu />
          <Volume className={styles.cell} />
          <CreditView />
          <UserAvatar className={styles.avatar} />
        </div>
      </div>
    </Toolbar>
    <Toolbar className={styles.toolbar2}>
      <div className={styles.left}>
      <DrawerNav />
      <div className={styles.logo} onClick={() => navigate('/')}></div>
      </div>
      <div className={styles.right}>
        <CreditView />
        <UserAvatar className={styles.avatar} />
      </div>
    </Toolbar>
    <div className={styles.line}></div>
  </AppBar>
})

export default AuthHeader