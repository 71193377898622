import React, { useEffect, useState } from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import { useNavigate } from "@reach/router"
import userStore from '../common/userStore'
import useWindowSize from '../../hooks/window-size'
import classes from './Welcome.module.scss'
import Button from '@mui/material/Button';
import HomePacks from '../home/HomePacks'

import pcMp4 from '../../images/pc/welcome.mp4'
import mbMp4 from '../../images/mb/mb-welcome.mp4'

import Header from '../header/Header'
import Footer from '../footer/Footer'

import Slide from '@mui/material/Slide';
import { scroll } from '../../utils'
import { useLocation } from "@reach/router"

const useSlide = (id, dir) => {
    const [show, setShow] = useState(false)
    const [direction, setDirection] = useState("up")
    useEffect(() => {
        const t = setInterval(() => {
            let seg = document.getElementById(id)
            if (seg) {
                let rc = seg.getBoundingClientRect();
                if (rc.top < 0 || rc.top + rc.height < window.innerHeight + 100) {
                    setShow(true)
                }
            }
            if (window.innerWidth > 700) {
                setDirection(dir)
            }
        }, 250)
        return () => {
            clearInterval(t)
        }
    }, []);
    return {
        show, direction
    }
}


const Welcome = observer(() => {
    const navigate = useNavigate();
    const [fixHeader, setFixHeader] = useState(false)
    const windowSize = useWindowSize();
    const seg1 = useSlide("welcome-seg1", "right")
    const seg2 = useSlide("welcome-seg2", "left");
    const seg3 = useSlide("welcome-seg3", "right");
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])
    useEffect(() => {
        const title = `Session Loops - Create music loops online`
        document.title = title
        return () => {
            document.title = title
        }
    }, [])

    useEffect(() => {
        if (userStore.isLogin) {
            navigate('/', {replace: true})
        }
    }, [userStore.isLogin])

    useEffect(() => {
        const t = setInterval(() => {
            setFixHeader(window.scrollY > 75)
        }, 350)
        return () => {
            clearInterval(t)
        }
    }, [])

    const mp4 = windowSize.width > 500 ? pcMp4 : mbMp4;

    return <div className={classes.root}>
        <Header fix={fixHeader} />
        <div className={classes.seg}>
            <video autoPlay loop muted playsInline>
                <source src={mp4} type="video/mp4" />
            </video>

            <div className={classes.introduction}>
                <h1>SESSION LOOPS</h1>
                <p>AI-powered music production tools for<br />next generation musicians</p>
            </div>
        </div>
        {/* <div className={classes.sale} onClick={() => navigate('/drumnet#buys')}/> */}
        <div className={classes.seg1} id="welcome-seg1">
            <div className={classes.vocalnet}>
                <h2>VocalNet</h2>
                <p style={{lineHeight:1.3, marginBottom: 10}}>Transform voices in seconds with unlimited community support</p>
                <Button variant="contained" disableElevation onClick={() => navigate('/vocalnet')}>Find out More &gt;</Button>
            </div>

            <Slide direction={seg1.direction} in={seg1.show} mountOnEnter unmountOnExit timeout={800}>
            <div className={classes.appview}>

            </div>
            </Slide>
        </div>
        <div className={classes.seg2} id="welcome-seg2">
            <div className={classes.drumnet}>
                <h2>DrumNet</h2>
                <p style={{lineHeight:1.3, marginBottom: 10}}>Generate drum samples in seconds with infinite variations</p>
                <Button variant="contained" disableElevation onClick={() => navigate('/drumnet')}>Find out More &gt;</Button>
            </div>

            <Slide direction={seg2.direction} in={seg2.show} mountOnEnter unmountOnExit timeout={800}>
            <div className={classes.appview}>

            </div>
            </Slide>
        </div>

        <div className={classes.seg3} id="welcome-seg3">
            <div className={classes.custom}>
                <h2>Custom <br />
                    Sample Packs</h2>
                <p style={{lineHeight:1.3, marginBottom: 10}}>Make custom loops in seconds using cloud-based Loop Editor </p>
                <Button variant="contained" disableElevation onClick={() => navigate('/packs')}>Find out More &gt;</Button>
            </div>

            <Slide direction={seg3.direction} in={seg3.show} mountOnEnter unmountOnExit timeout={800}>
            <div className={classes.video}>

            </div>
            </Slide>
        </div>
        <HomePacks />
        <Footer />
    </div>
});

export default Welcome
