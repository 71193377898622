import React from 'react'
import { observer, useLocalObservable } from "mobx-react-lite"
import {useNavigate} from '@reach/router'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link'
import Avatar from '@mui/material/Avatar';

import classes from './UserAvatar.module.scss'
// import NavLink from '../../components/NavLink';
import userStore from '../common/userStore'
import Video, {useVideo} from '../help/video';
import Footer from '../footer/Footer';

const DrawerNav = observer(() => {
  const navigate = useNavigate()
  const model = useLocalObservable(() => ({
    open: false,
    toggleDrawer() {
      this.open = !this.open
    }
  }))
  const videoModel = useVideo();
  const {name, email, avatar} = userStore.user;
  const to = (link) => {
    navigate(link)
  }

  return <>
    <IconButton edge="start" color="inherit" aria-label="menu" onClick={model.toggleDrawer}>
      <MenuIcon />
    </IconButton>
    <SwipeableDrawer open={model.open} onOpen={model.toggleDrawer} onClose={model.toggleDrawer} classes={{paper: classes.drawerPaper}}>
    <Box style={{width: 250}}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Avatar className={classes.avatar2} src={avatar}>{name && name.substr(0, 1).toUpperCase()}</Avatar>
        </div>
        <div className={classes.headerRight}>
          <div className={classes.displayName}>{name}</div>
          <div className={classes.email}>{email}</div>
        </div>
      </div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/")}>Browse </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/my-library")}>My Library </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/vocalnet")}>VocalNet </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/drumnet")}>DrumNet </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/profile")}>Account Settings </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/plan")}>Plan </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/account/billing")}>Billing </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={() => to("/help/faq")}>FAQ </Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={videoModel.show}>Video Tutorial</Link></div>
      <div className={classes.linkRow}><Link underline="none" href='https://sessionloops.firstpromoter.com' target="_blank">Affiliates</Link></div>
      <div className={classes.linkRow}><Link underline="none" onClick={userStore.logout}>Log out </Link></div>
    </Box>
    <Footer mini />
    </SwipeableDrawer>
    <Video open={videoModel.open} onClose={videoModel.hide} />
  </>
});

export default DrawerNav;